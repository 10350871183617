<template>
  <bg-modal
    :value="isOpen"
    desktop-size="lg"
    class="homepage-filter-modal"
    button-main-text="Terapkan"
    button-second-text="Reset"
    :body-scroll="false"
    @click-second-action="resetFilter"
    @click-main-action="applyFilter"
    @input="closeModal"
  >
    <div class="mb-32">
      <bg-text tag="h4" size="heading-4">Filter</bg-text>
      <bg-text size="body-2"> Silakan pilih sesuai kebutuhan anda </bg-text>
    </div>

    <bg-grid>
      <bg-grid-item :col="12" class="mb-16">
        <bg-text size="title-3">Tanggal Live</bg-text>
      </bg-grid-item>
      <bg-grid-item :col="12">
        <date-range-picker
          v-model="liveDate"
          id="homeFilterModalDate"
          start-date-label="Tanggal Mulai"
          end-date-label="Tanggal Akhir"
          :required="false"
        />
      </bg-grid-item>
    </bg-grid>

    <bg-grid>
      <bg-grid-item :col="12" class="mb-16">
        <bg-text size="title-3">Tanggal Expired</bg-text>
      </bg-grid-item>
      <bg-grid-item :col="12">
        <date-range-picker
          v-model="expiredDate"
          start-date-label="Tanggal Mulai"
          end-date-label="Tanggal Akhir"
          :required="false"
        />
      </bg-grid-item>
    </bg-grid>

    <bg-grid>
      <bg-grid-item :col="12" class="mb-16">
        <bg-text size="title-3">Status Kontrak</bg-text>
      </bg-grid-item>
      <bg-grid-item :col="6">
        <search-checkbox
          name="contract-status"
          :list="contractStatus.lists"
          :checked="contractStatus.selected"
          :max-checked="0"
          placeholder="Pilih Status Kontrak"
          disable-search
          disable-clear
          dropdown-menu-placement="top-start"
          @emit-checked-array="setSelectedValueTo('contract_status', $event)"
        />
      </bg-grid-item>
      <bg-grid-item :col="6">
        <search-checkbox
          name="product"
          :list="product.lists"
          :checked="product.selected"
          :is-loading-list="product.loading"
          :max-checked="0"
          placeholder="Pilih produk"
          disable-search
          disable-clear
          dropdown-menu-placement="top-start"
          @emit-checked-array="setSelectedValueTo('product', $event)"
          @dropdown-open="fetchProductList"
        />
      </bg-grid-item>
    </bg-grid>

    <bg-grid>
      <bg-grid-item :col="12" class="mb-16 mt-32">
        <bg-text size="title-3">Penanggung Jawab Properti</bg-text>
      </bg-grid-item>
    </bg-grid>
    <bg-grid desktop-only>
      <bg-grid-item :col="3">
        <search-checkbox
          name="bse"
          :list="other.BSELists"
          :checked="other.selectedBSE"
          :is-loading-list="other.loading"
          :max-checked="0"
          placeholder="Pilih BSE"
          disable-search
          disable-clear
          dropdown-menu-placement="top-start"
          @emit-checked-array="setSelectedValueTo('bse', $event)"
          @dropdown-open="fetchOfficerList"
        />
      </bg-grid-item>
      <bg-grid-item :col="3">
        <search-checkbox
          name="bd"
          :list="other.BDLists"
          :checked="other.selectedBD"
          :is-loading-list="other.loading"
          :max-checked="0"
          placeholder="Pilih BD"
          disable-search
          disable-clear
          dropdown-menu-placement="top-start"
          @emit-checked-array="setSelectedValueTo('bd', $event)"
          @dropdown-open="fetchOfficerList"
        />
      </bg-grid-item>
      <bg-grid-item :col="3">
        <search-checkbox
          name="as"
          :list="other.ASLists"
          :checked="other.selectedAS"
          :is-loading-list="other.loading"
          :max-checked="0"
          placeholder="Pilih AS"
          disable-search
          disable-clear
          dropdown-menu-placement="top-start"
          @emit-checked-array="setSelectedValueTo('as', $event)"
          @dropdown-open="fetchOfficerList"
        />
      </bg-grid-item>
      <bg-grid-item :col="3">
        <search-checkbox
          name="hospitality"
          :list="other.hospitalityLists"
          :checked="other.selectedHospitality"
          :is-loading-list="other.loading"
          :max-checked="0"
          placeholder="Pilih Hospitality"
          disable-search
          disable-clear
          dropdown-menu-placement="top-start"
          @emit-checked-array="setSelectedValueTo('hospitality', $event)"
          @dropdown-open="fetchOfficerList"
        />
      </bg-grid-item>
    </bg-grid>

    <bg-grid desktop-only>
      <bg-grid-item :col="12" class="mb-16 mt-32">
        <bg-text size="title-3">Informasi Properti</bg-text>
      </bg-grid-item>
      <bg-grid-item :col="6">
        <search-checkbox
          label="Kota"
          name="city"
          :list="city.lists"
          :checked="city.selected"
          :is-loading-list="city.loading"
          :placeholder="cityPlaceholder"
          :is-show-counter="false"
          :max-checked="1"
          selectType="single"
          search-placeholder="Cari Kota"
          dropdown-menu-placement="top-start"
          @emit-checked-array="setSelectedValueTo('city', $event)"
          @dropdown-open="fetchCityList"
        />
      </bg-grid-item>
      <bg-grid-item :col="6">
        <search-checkbox
          label="Kecamatan"
          name="district"
          :list="district.lists"
          :checked="district.selected"
          :is-loading-list="district.loading"
          :max-checked="5"
          placeholder="Pilih kecamatan"
          search-placeholder="Cari Kecamatan"
          :disable-search="!isCitySelected"
          :disable-clear="!isCitySelected"
          dropdown-menu-placement="top-start"
          @emit-checked-array="setSelectedValueTo('district', $event)"
          @dropdown-open="fetchDistrictList"
      /></bg-grid-item>
    </bg-grid>

    <bg-grid desktop-only>
      <bg-grid-item :col="6">
        <search-checkbox
          label="Tipe Kos"
          name="kosType"
          :list="kosType.lists"
          :checked="kosType.selected"
          :max-checked="0"
          placeholder="Pilih tipe kos"
          @emit-checked-array="setSelectedValueTo('genders', $event)"
          disable-search
          disable-clear
          dropdown-menu-placement="top-start"
        />
      </bg-grid-item>
      <bg-grid-item :col="6">
        <search-checkbox
          label="Fasilitas"
          name="facility"
          :list="facility.lists"
          :checked="facility.selected"
          :is-loading-list="facility.loading"
          :category-list="facility.categories"
          :max-checked="0"
          placeholder="Pilih fasilitas kos"
          search-placeholder="Cari fasilitas"
          @emit-checked-array="setSelectedValueTo('facilities', $event)"
          @dropdown-open="fetchFacilityList"
          dropdown-menu-placement="top-start"
        />
      </bg-grid-item>
    </bg-grid>

    <bg-grid desktop-only>
      <bg-grid-item :col="6">
        <search-checkbox
          label="Aturan Kos"
          name="kosRule"
          :list="kosRule.lists"
          :checked="kosRule.selected"
          :is-loading-list="kosRule.loading"
          :max-checked="0"
          placeholder="Pilih aturan kos"
          @emit-checked-array="setSelectedValueTo('rules', $event)"
          disable-search
          disable-clear
          dropdown-menu-placement="top-start"
          @dropdown-open="fetchKosRule"
        />
      </bg-grid-item>
      <bg-grid-item :col="6">
        <form-input-nearby-location
          @on-select-location="setSelectedValueTo('spot', $event)"
        />
      </bg-grid-item>
    </bg-grid>

    <form-price-input @on-input-price="setSelectedValueTo('price', $event)" />
  </bg-modal>
</template>

<script>
import { BgModal, BgText, BgGrid, BgGridItem } from 'bangul-vue';
import DateRangePicker from '@admin_molecules/DateRangePicker';
import SearchCheckbox from '@admin_molecules/SearchCheckbox';
import { mapMutations, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { capitalize } from 'Utils/typography';
import homepageApi from '@admin_endpoints/homepage';
import FormPriceInput from './components/FormPriceInput/FormPriceInput.vue';
import FormInputNearbyLocation from './components/FormInputNearbyLocation/FormInputNearbyLocation.vue';
import contractStatusOptions, {
  selectedContractStatusDefault,
} from './data/contractStatusOptions';

export default {
  name: 'HomepageFilterModal',

  components: {
    BgModal,
    BgText,
    BgGrid,
    BgGridItem,
    DateRangePicker,
    FormPriceInput,
    SearchCheckbox,
    FormInputNearbyLocation,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      liveDate: {
        startDate: null,
        endDate: null,
      },
      expiredDate: {
        startDate: null,
        endDate: null,
      },
      city: {
        loading: false,
        selected: [],
        lists: [],
      },
      district: {
        lists: [],
        selected: [],
        loading: false,
      },
      kosType: {
        lists: [
          {
            label: 'Putra',
            value: 'putra',
          },
          {
            label: 'Putri',
            value: 'putri',
          },
          {
            label: 'Campur',
            value: 'campur',
          },
        ],
        selected: [],
      },
      product: {
        loading: false,
        selected: [],
        lists: [],
      },
      kosRule: {
        lists: [],
        loading: false,
        selected: [],
      },
      facility: {
        lists: [],
        loading: false,
        selected: [],
        categories: [],
      },
      other: {
        loading: false,
        selectedBSE: [],
        BSELists: [],
        selectedBD: [],
        BDLists: [],
        selectedAS: [],
        ASLists: [],
        selectedHospitality: [],
        hospitalityLists: [],
      },
      contractStatus: {
        selected: [],
        lists: contractStatusOptions,
      },
      price: {
        min: '',
        max: '',
      },
      nearbyLocation: '',
    };
  },

  computed: {
    ...mapGetters('homepage', ['totalFilter', 'getFilter']),

    isCitySelected() {
      return this.city?.selected?.length > 0;
    },

    cityPlaceholder() {
      return this.city.selected?.length <= 0
        ? 'Pilih kota'
        : this.city.lists.filter(
            list => list.value === this.city.selected[0]
          )[0].label;
    },
  },

  watch: {
    isOpen(value) {
      if (value) {
        this.setSelectedFilter();
      }
    },
  },

  filters: {
    facilityCategory(value) {
      switch (value) {
        case 'fac_room':
          return 'Fasilitas Kamar';
        case 'fac_share':
          return 'Fasilitas Bersama Kos';
        case 'fac_park':
          return 'Fasilitas Parkir';
        case 'fac_bath':
          return 'Fasilitas Kamar Mandi';
        default:
          return '';
      }
    },
  },

  methods: {
    ...mapMutations('homepage', ['setFilter', 'setPagination']),

    closeModal() {
      this.$emit('update:isOpen', false);
      this.resetFilterState();
    },

    showErrorToast(message) {
      this.$toast.show(message, { duration: 4 });
    },

    setSelectedFilter() {
      if (this.totalFilter) {
        this.city.selected = this.getFilter('area_city', []);
        this.district.selected = this.getFilter('area_district', []);
        this.product.selected = this.getFilter('property_type', []);
        this.other.selectedBSE = this.getFilter('bse', []);
        this.kosType.selected = this.getFilter('genders', []);
        this.kosRule.selected = this.getFilter('rules', []);
        this.facility.selected = this.getFilter('facilities', []);
        this.other.selectedBD = this.getFilter('agent', []);
        this.other.selectedAS = this.getFilter('account_support', []);
        this.other.selectedHospitality = this.getFilter('supervisor', []);
        this.liveDate = {
          startDate: this.stringDate(this.getFilter('contract_start_from')),
          endDate: this.stringDate(this.getFilter('contract_start_to')),
        };
        this.expiredDate = {
          startDate: this.stringDate(this.getFilter('contract_end_from')),
          endDate: this.stringDate(this.getFilter('contract_end_to')),
        };
        this.contractStatus.selected = this.getFilter('contract_status', []);
      }
    },

    resetFilterState() {
      this.liveDate = {
        startDate: null,
        endDate: null,
      };

      this.expiredDate = {
        startDate: null,
        endDate: null,
      };
      this.city.selected = [];
      this.district.selected = [];
      this.product.selected = [];
      this.kosType.selected = [];
      this.kosRule.selected = [];
      this.facility.selected = [];
      this.other.selectedBSE = [];
      this.other.selectedBD = [];
      this.other.selectedAS = [];
      this.other.selectedHospitality = [];
      this.contractStatus.selected = [];
      this.price = { min: '', max: '' };
      this.nearby_landmarks = '';
      this.$toast.clear();
    },

    resetFilter() {
      this.setFilter({
        contract_status: selectedContractStatusDefault,
      });
      this.setPagination({ offset: 0 });
      this.$emit('action-apply-filter');
      this.closeModal();
    },

    applyFilter() {
      const filters = {
        contract_start_from: this.formatDate(this.liveDate.startDate),
        contract_start_to: this.formatDate(this.liveDate.endDate),
        contract_end_from: this.formatDate(this.expiredDate.startDate),
        contract_end_to: this.formatDate(this.expiredDate.endDate),
        area_city: this.city.selected,
        area_district: this.district.selected,
        property_type: this.product.selected,
        bse: this.other.selectedBSE,
        agent: this.other.selectedBD,
        account_support: this.other.selectedAS,
        supervisor: this.other.selectedHospitality,
        contract_status: this.contractStatus.selected,
        genders: this.kosType.selected,
        facilities: this.facility.selected,
        rules: this.kosRule.selected,
        min_price: this.price.min,
        max_price: this.price.max,
        nearby_landmarks: this.nearbyLocation,
      };

      /** Set default status when selected nothing */
      if (!filters.contract_status.length) {
        filters.contract_status = [...selectedContractStatusDefault];
      }

      this.setFilter(
        Object.entries(filters).reduce((acc, [key, value]) => {
          if (value && value.length) {
            acc[key] = value;
          }

          return acc;
        }, {})
      );
      this.$emit('action-apply-filter');
      this.closeModal();
    },

    formatDate(date) {
      return date ? dayjs(date).format('YYYY-MM-DD') : null;
    },

    stringDate(date) {
      return date ? dayjs(date).toDate() : null;
    },

    setSelectedValueTo(type, value) {
      switch (type) {
        case 'city':
          this.city.selected = [...value];
          this.district.lists = [];
          this.district.selected = [];
          break;

        case 'district':
          this.district.selected = [...value];
          break;

        case 'product':
          this.product.selected = [...value];
          break;

        case 'bse':
          this.other.selectedBSE = [...value];
          break;

        case 'bd':
          this.other.selectedBD = [...value];
          break;

        case 'as':
          this.other.selectedAS = [...value];
          break;

        case 'hospitality':
          this.other.selectedHospitality = [...value];
          break;

        case 'contract_status':
          this.contractStatus.selected = [...value];
          break;

        case 'genders':
          this.kosType.selected = [...value];
          break;

        case 'rules':
          this.kosRule.selected = [...value];
          break;

        case 'facilities':
          this.facility.selected = [...value];
          break;

        case 'price':
          this.price = {
            min: value.min,
            max: value.max,
          };
          break;

        case 'spot':
          this.nearbyLocation = [...value];
          break;

        default:
          break;
      }
    },

    async fetchKosRule() {
      if (!this.kosRule.loading && !this.kosRule.lists.length) {
        this.kosRule.loading = true;

        try {
          const { data } = await homepageApi.getFilterTag('kost_rule');

          if (data) {
            this.kosRule.lists = data.tags.map(each => ({
              label: each.name,
              value: each.id,
            }));
          }
        } catch (error) {
          console.error(error);
          this.showErrorToast('Gagal saat mendapatkan data aturan kos');
        } finally {
          this.kosRule.loading = false;
        }
      }
    },

    async fetchFacilityList() {
      if (!this.facility.loading && !this.facility.lists.length) {
        this.facility.loading = true;

        try {
          const { data } = await homepageApi.getFilterTag('all_facilities');

          if (data) {
            const categoryList = [
              ...new Set(data.tags.map(item => item.tag_type)),
            ];
            this.facility.lists = data.tags.map(each => ({
              label: each.name,
              value: each.id,
              type: each.tag_type,
            }));
            this.facility.categories = categoryList.map(each => ({
              value: each,
              label: this.$options.filters.facilityCategory(each),
            }));
          }
        } catch (error) {
          console.error(error);
          this.showErrorToast('Gagal saat mendapatkan data fasilitas');
        } finally {
          this.facility.loading = false;
        }
      }
    },

    async fetchDistrictList() {
      if (!this.city.selected.length) {
        this.showErrorToast('Pilih Kota terlebih dahulu !');
        return;
      }
      if (!this.district.loading && !this.district.lists?.length) {
        this.district.loading = true;

        try {
          const { data } = await homepageApi.getFilterDistrict(
            this.city.selected
          );

          if (data.status) {
            this.district.lists = data.data.map(each => ({
              label: each.name,
              value: each.id,
            }));
          }
        } catch (error) {
          console.error(error);
          alert('Gagal saat mendapatkan data Kecamatan');
        } finally {
          this.district.loading = false;
        }
      }
    },

    async fetchCityList() {
      if (!this.city.loading && !this.city.lists.length) {
        this.city.loading = true;

        try {
          const { data } = await homepageApi.getFilterCity();

          if (data.status) {
            this.city.lists = data.data.map(each => ({
              label: each.name,
              value: each.id,
            }));
          }
        } catch (error) {
          console.error(error);
          this.showErrorToast('Gagal saat mendapatkan data Kota');
        } finally {
          this.city.loading = false;
        }
      }
    },

    async fetchProductList() {
      if (!this.product.loading && !this.product.lists.length) {
        this.product.loading = true;

        try {
          const { data } = await homepageApi.getFilterProduct();

          if (data.status) {
            this.product.lists = data.data.map(each => ({
              label: capitalize(each.name),
              value: each.id,
            }));
          }
        } catch (error) {
          console.error(error);
          this.showErrorToast('Gagal saat mendapatkan data Produk');
        } finally {
          this.product.loading = false;
        }
      }
    },

    async fetchOfficerList() {
      const needFetchData = [
        'BSELists',
        'BDLists',
        'ASLists',
        'hospitalityLists',
      ].some(each => !this.other[each].length);

      function mappingData(datas) {
        return !datas ? [] : datas.map(data => ({ label: data, value: data }));
      }

      if (!this.other.loading && needFetchData) {
        this.other.loading = true;

        try {
          const { data } = await homepageApi.getFilterOfficer();

          if (data.status) {
            this.other.BSELists = mappingData(data.data.bse_name);
            this.other.BDLists = mappingData(data.data.agent_name);
            this.other.ASLists = mappingData(data.data.account_support_name);
            this.other.hospitalityLists = mappingData(
              data.data.supervisor_name
            );
          }
        } catch (error) {
          console.error(error);
          this.showErrorToast(
            'Gagal saat mendapatkan data BSE, BD, AS dan Hospitality'
          );
        } finally {
          this.other.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./HomepageFilterModal.scss"></style>
