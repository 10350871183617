<template>
  <div class="mt-32 mb-16">
    <bg-text size="title-4">Harga Sewa</bg-text>
    <bg-grid class="mt-8">
      <bg-grid-item :col="6">
        <bg-field
          label="Minimal"
          :error="!isRangeOfPriceValid"
          :message="minGuidelineText"
        >
          <bg-input
            v-model="minPrice.input"
            type="string"
            placeholder="Rp"
            @input="setInputPrice('min')"
          />
        </bg-field>
      </bg-grid-item>
      <bg-grid-item :col="6">
        <bg-field
          label="Maksimal"
          :error="!isRangeOfPriceValid"
          :message="maxGuidelineText"
        >
          <bg-input
            v-model="maxPrice.input"
            type="string"
            placeholder="Rp"
            @input="setInputPrice('max')"
          />
        </bg-field>
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BgField, BgText, BgGrid, BgGridItem, BgInput } from 'bangul-vue';
import debounce from '@/utils/debounce';

const INPUT_DELAY_TIME = 300;
export default {
  name: 'FormPriceInput',
  data() {
    return {
      minPrice: { value: '', input: '' },
      maxPrice: { value: '', input: '' },
    };
  },

  components: {
    BgField,
    BgText,
    BgGrid,
    BgInput,
    BgGridItem,
  },
  computed: {
    ...mapGetters('homepage', ['getFilter']),

    isRangeOfPriceValid() {
      const maxPrice = parseInt(this.maxPrice.value);
      const minPrice = parseInt(this.minPrice.value);

      return (
        minPrice < maxPrice || (!!maxPrice === false && !!minPrice === false)
      );
    },

    maxGuidelineText() {
      return this.isRangeOfPriceValid
        ? 'Contoh: Rp2.000.000'
        : 'Harga sewa maksimal harus lebih dari harga sewa minimal.';
    },
    minGuidelineText() {
      return this.isRangeOfPriceValid
        ? 'Contoh: Rp500.000'
        : 'Harga sewa minimal harus kurang dari harga sewa maksimal.';
    },
  },

  created() {
    this.setLastInputPrice();
  },

  methods: {
    setInputPrice: debounce(function (formType) {
      if (formType === 'min') {
        const { originalValue, modifiedValue } = this.handleFormatPrice(
          this.minPrice.input
        );
        this.minPrice = {
          value: originalValue,
          input: modifiedValue,
        };
      } else {
        const { originalValue, modifiedValue } = this.handleFormatPrice(
          this.maxPrice.input
        );
        this.maxPrice = {
          value: originalValue,
          input: modifiedValue,
        };
      }
      const inputValue = { min: this.minPrice.value, max: this.maxPrice.value };
      this.$emit('on-input-price', inputValue);
    }, INPUT_DELAY_TIME),

    handleFormatPrice(inputPrice) {
      let modifiedValue = '';
      let originalValue = inputPrice.includes('Rp')
        ? inputPrice.slice(3)
        : inputPrice;
      originalValue = originalValue.split('.').join('');
      const isNumber = !/\D/.test(originalValue);

      if (!isNumber) {
        originalValue = '';
        return { originalValue, modifiedValue: 'Rp ' };
      }

      modifiedValue = `Rp ${originalValue.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        '$1.'
      )}`;
      return { originalValue, modifiedValue };
    },

    setLastInputPrice() {
      this.minPrice.input = this.getFilter('min_price', '');
      this.maxPrice.input = this.getFilter('max_price', '');

      if (this.minPrice.input.length > 0) this.setInputPrice('min');
      if (this.maxPrice.input.length > 0) {
        setTimeout(() => {
          this.setInputPrice('max');
        }, INPUT_DELAY_TIME);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPriceInput.scss"></style>
