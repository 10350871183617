export const validateObjectKeys = (...keys) => {
	const validator = function (value) {
		if (keys.every(key => Object.hasOwnProperty.call(value, key))) {
			return true;
		}

		console.warn(`The prop value is invalid`);
	};

	return validator;
};

export const chooseOne = (...options) => {
	const validator = function (value) {
		if (options.includes(value)) {
			return true;
		}

		console.warn(
			`The value "${value}" is invalid; expected one of "${options.join(
				'",  "'
			)}"`
		);
	};

	return validator;
};
