<template>
  <div class="date-range-picker" :id="id">
    <bg-grid desktop-only>
      <bg-grid-item :col="6" class="mb-0">
        <ValidationProvider
          name="start-date-range"
          :rules="{ required }"
          v-slot="{ failedRules }"
        >
          <bg-field
            :label="startDateLabel"
            :label-for="`${id}-datePickerStart`"
            :error="required ? !!failedRules.required : false"
          >
            <bg-datepicker
              placeholder="Pilih tanggal mulai"
              :value="value.startDate"
              :id="`${id}-datePickerStart`"
              :data-testid="`${id}-datePickerStart`"
              :disabled-dates="disableStartDate"
              :open-date="openingDate"
              @input="handleStartRangeInput"
            />
          </bg-field>
        </ValidationProvider>
      </bg-grid-item>
      <bg-grid-item :col="6" class="mb-0">
        <ValidationProvider
          name="end-date-range"
          :rules="{ required }"
          v-slot="{ failedRules }"
        >
          <bg-field
            :label="endDateLabel"
            :label-for="`${id}-datePickerEnd`"
            :error="required ? !!failedRules.required : false"
          >
            <bg-datepicker
              placeholder="Pilih tanggal akhir"
              fixed-position="bottom-right"
              :value="value.endDate"
              :id="`${id}-datePickerEnd`"
              :data-testid="`${id}-datePickerEnd`"
              :disabled-dates="disableEndDate"
              :open-date="openingDate"
              @input="handleEndRangeInput"
            />
          </bg-field>
        </ValidationProvider>
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import { BgField, BgDatepicker, BgGrid, BgGridItem } from 'bangul-vue';
import { validateObjectKeys } from 'Utils/propValidator';
import { dayjs } from 'Utils/formatter';

const defaultDisableDates = fixedMonth => {
  if (fixedMonth) {
    return {
      from: dayjs(fixedMonth).endOf('month').toDate(),
      to: dayjs(fixedMonth).startOf('month').toDate(),
    };
  }

  return {};
};

export default {
  name: 'DateRangePicker',

  components: {
    BgField,
    BgDatepicker,
    BgGrid,
    BgGridItem,
  },

  props: {
    value: {
      type: Object,
      required: true,
      validator: validateObjectKeys('startDate', 'endDate'),
    },
    startDateLabel: {
      type: String,
      default: '',
    },
    endDateLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: true,
    },
    fixedMonth: {
      type: [String, Date],
      default: '',
    },
  },

  data() {
    return {
      disableStartDate: defaultDisableDates(this.fixedMonth),
      disableEndDate: defaultDisableDates(this.fixedMonth),
    };
  },

  computed: {
    openingDate() {
      if (this.fixedMonth) {
        return dayjs(this.fixedMonth).toDate();
      }

      return undefined;
    },
  },

  methods: {
    manipulateDate(value) {
      if (this.fixedMonth) {
        const dayOfMonth = dayjs(value).date();

        return dayjs(this.fixedMonth).date(dayOfMonth).toDate();
      }

      return value;
    },

    handleStartRangeInput(value) {
      this.sendToParent({
        ...this.value,
        startDate: this.manipulateDate(value),
      });

      this.disableEndDate = {
        to: this.manipulateDate(value),
        from: this.fixedMonth
          ? dayjs(this.fixedMonth).endOf('month').toDate()
          : undefined,
      };
    },

    handleEndRangeInput(value) {
      this.sendToParent({
        ...this.value,
        endDate: this.manipulateDate(value),
      });

      this.disableStartDate = {
        from: this.manipulateDate(value),
        to: this.fixedMonth
          ? dayjs(this.fixedMonth).startOf('month').toDate()
          : undefined,
      };
    },

    sendToParent(value) {
      this.$emit('input', value);
    },
  },
};
</script>
