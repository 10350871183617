<template>
  <search-checkbox
    label="Lokasi Sekitar Kos"
    name="location"
    :list="location.lists"
    :checked="location.selected"
    :is-loading-list="location.loading"
    placeholder="Pilih lokasi sekitar kos "
    search-placeholder="Cari lokasi sekitar kos "
    :max-checked="0"
    dropdown-menu-placement="top-start"
    @emit-checked-array="setInputNearbyLocation"
    @dropdown-open="fetchLocationsList"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import SearchCheckbox from '@admin_molecules/SearchCheckbox';
import homepageApi from '@/_admin/api/endpoints/homepage';

export default {
  name: 'FormInputNearbyLocation',

  components: {
    SearchCheckbox,
  },

  data() {
    return {
      location: {
        loading: false,
        selected: [],
        lists: [],
      },
    };
  },

  computed: {
    ...mapGetters('homepage', ['getFilter', 'getLocationList']),

    lastLocationList() {
      return this.getLocationList;
    },
  },

  created() {
    this.setLastSelectedLocationList();
  },

  methods: {
    ...mapMutations('homepage', ['setLocationList']),

    setInputNearbyLocation(spot) {
      this.location.selected = spot;
      this.$emit('on-select-location', spot);
    },

    async fetchLocationsList() {
      if (this.lastLocationList.length > 0) {
        this.location.lists = this.lastLocationList;
        return;
      }

      try {
        this.location.loading = true;
        const { data } = await homepageApi.getNearbyLocations();

        if (data.categories) {
          this.location.lists = data.categories.map(each => ({
            label: each.name,
            value: each.id,
          }));

          this.setLocationList(this.location.lists);
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = 'Gagal saat mendapatkan lokasi sekitar';
        this.anyError = true;
      } finally {
        this.location.loading = false;
      }
    },

    setLastSelectedLocationList() {
      if (this.lastLocationList.length > 0) {
        this.location.lists = this.lastLocationList;
        this.location.selected = this.getFilter('nearby_landmarks', []);
      }
    },
  },
};
</script>
